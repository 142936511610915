
// Container to pass variables from templates
var APP = document.APP = window.APP = {};

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Loads the jquery package from node_modules
// $ = global.$ = global.jQuery = require('jquery');
require("expose-loader?$!jquery");

// Dummy custom script
// const dummy = require('./dummy.js');

require('chart.js');

// App
const taskForm = require('./task-form.js');
const projectForm = require('./project-form.js');
const invoiceForm = require('./invoice-form.js');
const formCollection = require('./form-collection.js');

// Autosize textarea
const autosize = require('../../node_modules/autosize/dist/autosize.min');

// Bootstrap
require('bootstrap');

// Datatables
require( 'datatables.net' )(window, window.$);
require( 'datatables.net-bs4' )(window, window.$);
require( 'datatables.net-fixedcolumns-bs4' )(window, window.$);
require( 'datatables.net-fixedheader-bs4' )(window, window.$);
require( 'datatables.net-responsive-bs4' )(window, window.$);
require( 'datatables.net-select-bs4' )(window, window.$);


// On page init
$(document).ready(function() {

	// Test
	// dummy.init();

	// Autosize
	autosize($('textarea'));
	$('textarea').on('focus', function(){
		autosize.update($(this));
	});

	// App init
	taskForm.init();
	projectForm.init();
	invoiceForm.init();
	formCollection.init();
	formCollection.onSpawn = function(){
		autosize($('textarea'));
	};
	
	// BS4
	$('[data-toggle="tooltip"]').tooltip();
	$('[data-toggle="popover"]').popover();
	
	// Side
	$('body').on('click', '[data-action=toggleSide]', function(e){
		$(e.delegateTarget).toggleClass('openSide');
		return false;
	});

	// Ouvrir les tabs ciblées en hash
	if(document.location.hash){
		var $tabLink = $('.nav-tabs a[href="'+document.location.hash+'"]');
		$tabLink.trigger('click');
	}

	// Si aucune tab sélectionnée
	var $tabLinks = $('.nav-tabs a');
	var $tabLinksActive = $('.nav-tabs a.active');
	if($tabLinks.length > 0 && $tabLinksActive.length === 0)
		$tabLinks.first().addClass('active');

	// Keep tabs
	$tabLinks.on('click', function(){
		document.location.hash = $(this).attr('href');
	})


});
