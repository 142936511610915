module.exports = {
	onSpawn: null,
	
	init: function(){
		let inst = this;
		inst.$prototypeContainers = $('[data-prototype]');
		
		// Set index
		inst.$prototypeContainers.each(function(){
			// ...au nombre d'enfants directs
			$(this).data('index', $(this).find('>*').length)
		});
		
		// On click add
		$('form').on('click', '[data-action=addCollectionItem]', function(){
			const selector = $(this).data('target');
			const $container = $(selector);
			
			if($container.length)
				inst.spawnPrototype($container);
			else
				console.error("Protype non trouvé pour le sélecteur " + selector);
		});
		
		// On click remove
		$('form').on('click', '[data-action=removeCollectionItem]', function(){
			let $item = $(this).parentsUntil('[data-prototype]').last();
			
			// if(confirm('Voulez-vous vraiment supprimer cette ligne ?'))
			inst.remove($item);
		});
		
	},
	
	spawnPrototype: function($container)
	{
		const prototype = $container.data('prototype');
		
		// get the new index
		let index = $container.data('index');
		
		let newForm = prototype;
		
		// You need this only if you didn't set 'label' => false in your tags field in TaskType
		// Replace '__name__label__' in the prototype's HTML to
		// instead be a number based on how many items we have
		// newForm = newForm.replace(/__name__label__/g, index);
		
		// Replace '__name__' in the prototype's HTML to
		// instead be a number based on how many items we have
		newForm = newForm.replace(/__name__/g, index);
		
		// increase the index with one for the next item
		$container.data('index', index + 1);
		
		// Display the form in the page in an li, before the "Add a tag" link li
		$container.append(newForm);
		
		// Callback
		if(this.onSpawn)
			this.onSpawn();
	},
	
	remove: function($item)
	{
		$item.remove();
	}
};
