
module.exports = {

	init: function(){
		var inst = this;

		inst.$form = $('form[name=task]');
		inst.$durationUnitInput = inst.$form.find('[name=task\\[durationUnit\\]]');
		inst.$durationInDaysInput = inst.$form.find('[name=task\\[durationInDays\\]]');
		inst.$durationInHoursInput = inst.$form.find('[name=task\\[durationInHours\\]]');
		inst.$durationInMinutesInput = inst.$form.find('[name=task\\[durationInMinutes\\]]');
		inst.$billingStatusInput = inst.$form.find('[name=task\\[billingStatus\\]]');
		inst.$priceInput = inst.$form.find('[name=task\\[price\\]]');
		inst.$priceSuggestionApplyButton = inst.$form.find('[data-action=applySuggestedPrice]');
		
		inst.durationUnit = inst.$durationUnitInput.val();
		inst.suggestedPrice = null;
		
		// Modification de la temporalité
		inst.updateDurationDisp();
		inst.$durationUnitInput.on('change', function(e){
			inst.updateDurationDisp();
		});
		
		// Suggestion du tarif au changement de temps
		inst.$durationInDaysInput.on('change keyup', function(e){
			inst.suggestPrice();
		});
		inst.$durationInHoursInput.on('change keyup', function(e){
			inst.suggestPrice();
		});
		inst.$durationInMinutesInput.on('change keyup', function(e){
			inst.suggestPrice();
		});
		
		// Affichage ou non des options de facturation
		inst.updateBillingDisp();
		inst.$billingStatusInput.on('change', function(e){
			inst.updateBillingDisp();
		});

		// Autofocus
		inst.$form.find('input:first').focus();
		
		
		// Appliquer le prix suggéré
		inst.$priceSuggestionApplyButton.on('click', function(){
			inst.$priceInput.val( inst.suggestedPrice );
		})
	},
	
	updateBillingDisp: function () {
		var inst = this;
		var billingStatus = inst.$billingStatusInput.val();
		
		// Masquer le montant
		// inst.$priceInput.parents('[class^=col-]').toggle('free' !== billingStatus);
		
		// Changement de la légende du montant
		inst.$priceInput.parents('[class^=col-]').find('label').text(
			'free' === billingStatus ? 'Prix indicatif' : 'Montant à facturer'
		);
	},

	updateDurationDisp: function () {
		var inst = this;
		var newVal;
		var $valInputs;

		// Unit?
		var newDurationUnit = inst.$durationUnitInput.val();
		
		// Dupliquer les valeurs au changement
		if(inst.durationUnit !== newDurationUnit){
			// Current value :
			$valInputs = inst.$durationInDaysInput.parent().find('input');
			newVal = $valInputs.filter(':visible').val();
			if('minute' === newDurationUnit)
				newVal = Math.floor(newVal);
			$valInputs.val(newVal);
		}
		
		// Les minutes sont en fait des jours
		if(inst.durationUnit !== 'day' && newDurationUnit === 'day')
			inst.$durationInDaysInput.val(
				inst.$durationInMinutesInput.val()
			);
		
		// Toggle unit
		inst.durationUnit = newDurationUnit;

		// Afficher /masquer
		inst.$durationInDaysInput.toggle('day' === newDurationUnit);
		inst.$durationInHoursInput.toggle('hour' === newDurationUnit);
		inst.$durationInMinutesInput.toggle('minute' === newDurationUnit);
		
		// Déduire un prix
		inst.suggestPrice();
	},
	
	suggestPrice: function() {
		var inst = this;
		var durationUnit = inst.$durationUnitInput.val();
		var duration;
		
		switch (durationUnit) {
			case 'day':
				duration = inst.$durationInDaysInput.val();
				inst.suggestedPrice = duration * APP.rateByDay;
				break;
			case 'hour':
				duration = inst.$durationInHoursInput.val();
				inst.suggestedPrice = duration * APP.rateByHour;
				break;
			case 'minute':
				duration = inst.$durationInMinutesInput.val();
				inst.suggestedPrice = duration * (APP.rateByHour / 60);
				break;
		}
		
		// Format
		if(inst.suggestedPrice)
			inst.suggestedPrice = inst.suggestedPrice.toFixed(2);
		
		// Suggest
		inst.$priceInput.attr('placeholder', inst.suggestedPrice ? 'ex. ' + inst.suggestedPrice + ' €' : '');
		
		// Magic wand
		inst.$priceSuggestionApplyButton.prop('disabled', inst.suggestedPrice <= 0)
			.toggleClass('text-muted', inst.suggestedPrice <= 0)
		;
	}

};
