
module.exports = {

	init: function(){
		var inst = this;

		inst.$form = $('form[name=invoice], form[name=invoice_details], form[name=invoice_status]');
		inst.$typeInput = inst.$form.find('[name=invoice\\[type\\]],[name=invoice_details\\[type\\]]');
		inst.$percentInput = inst.$form.find('[name=invoice\\[percent\\]],[name=invoice_details\\[percent\\]]');
		inst.$paidInput = inst.$form.find('[name$=\\[isPaid\\]]');
		inst.$paymentDateInput = inst.$form.find('[name$=\\[paymentDate\\]]');
		inst.$paymentDateWrapper = inst.$paymentDateInput.parents('[class^="col-"]').first();
		inst.$punctionInput = inst.$form.find('[name$=\\[isPunctured\\]]');
		inst.$punctionWrapper = inst.$punctionInput.parents('[class^="col-"]').first();
		inst.$typeInput.on('change keyup', function(e){
			inst.updateDisp();
		});
		inst.$paidInput.on('change focus', function(e){
			inst.updateStatusDisp();
		});
		
		inst.updateDisp();
		inst.updateStatusDisp();
		
		// Autofocus
		inst.$form.find('input:first').focus();
	},
	
	updateDisp: function () {
		var inst = this;
		var type = inst.$typeInput.val();
		var $container = inst.$percentInput.parents('[class^=col-]').first();
		
		$container.toggle(['acompte','complement'].includes(type));
	},
	
	updateStatusDisp: function () {
		var inst = this;
		var isPaid = inst.$paidInput.prop('checked');
		
		inst.$paymentDateWrapper.toggle(isPaid);
		inst.$paymentDateInput.prop('required', isPaid);

		inst.$punctionWrapper.toggle(isPaid);

		if(true !== isPaid)
			inst.$paymentDateInput.val(null);
	}

};
