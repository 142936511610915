
module.exports = {

	init: function(){
		var inst = this;

		inst.$form = $('form[name=project]');
		inst.$rateInputs = inst.$form.find('[name=project\\[rateByDay\\]],[name=project\\[rateByHalfDay\\]],[name=project\\[rateByHour\\]]');

		inst.updateNet();

		inst.$rateInputs.on('change keyup', function(e){
			inst.updateNet();
		});

		// Autofocus
		inst.$form.find('input:first').focus();
	},

	updateNet: function () {
		var inst = this;
		
		inst.$rateInputs.each(function(){
			var rate = parseFloat( $(this).val() );
			var net, taxBrut;
			
			if(isNaN(rate))
				net = '...';
			else
				net = (rate - (rate / 100 * APP.tax)).toFixed(2);
			
			$(this).next().find('.net').text(net);
		});
	}

};
